/* Dashboard */
.dashboard-vector-map {
    width: 100%;
    height: 300px;
}
.proBanner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    .purchase-popup {
      @extend .grid-margin;
      background: #000;
      color: #fff;
      padding: 15px 20px;
      @include border-radius(3px);
  
      .btn {
        margin-right: 20px;
        font-weight: 500;
        color: $white;
        @include border-radius(5px);
        @include transition-duration(0.2s);
  
        &.download-button {
          background: rgba(249, 249, 249, 0.7);
          color: #969292;
          border: 1px solid darken(#e4e4e4,5%);
        }
  
        &.purchase-button {
          background-color: theme-color(info);
          color: $white;
          border: none;
          line-height: 1;
          vertical-align: middle;
        }
      }
  
      p {
        margin-bottom: auto;
        margin-top: auto;
        color: darken(#e4e4e4,20%);
        font-weight: 400;
        vertical-align: middle;
        line-height: 1;
      }
  
      i {
        vertical-align: middle;
        line-height: 1;
        margin: auto 0;
        color: darken(#e4e4e4,20%);
      }
      .bannerClose {
        cursor: pointer;
      }
    }
    &.hide {
      display: none;
    }
  }